// Layout.js
import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Box } from "@mui/material";

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      <Box>{children}</Box>
      <Footer sx={{ marginTop: "20px" }} />
    </>
  );
};

export default Layout;
