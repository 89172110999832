import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Tab,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import BxiLogo from "../../assets/BXI_LOGO.png";
import whitearr from "../../assets/whitearr.png";
import Arrow from "../../assets/LoginArrow.svg";
import { useNavigate } from "react-router-dom/dist";
import React, { useState } from "react";
import TabContext from "@mui/lab/TabContext";
import DrawerComp from "./DrawerComp";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Navbar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const [value, setValue] = useState("1");
  const [imgArr, setImgArr] = useState(false);
  const [border, SetBorder] = useState(false);
  const currentLocation = window.location.pathname;
  const checkactive = () => {
    if (currentLocation.includes("home")) {
      SetBorder(true);
    } else if (currentLocation.includes("howitworks")) {
      SetBorder(true);
    } else {
      SetBorder(false);
    }
  };
  useEffect(() => {
    checkactive();
  });
  const handleToast = () => {
    navigate("/login");
  };
  const pages = [
    { name: "Home", link: "/", id: "1", text: "/" },
    {
      name: "Learn Barter",
      link: "/learnbarter",
      id: "2",
      text: "/learnbarter",
    },
    { name: "About", link: "/about", id: "3", text: "/about" },
    {
      name: "Marketplace",
      link: "/marketplace",
      id: "4",
      text: "/marketplace",
    },
  ];
  return (
    <React.Fragment>
      <ToastContainer style={{ fontSize: "16px" }} />
      <AppBar
        position="fixed"
        sx={{
          width: "100%",
          background: "#fff",
          boxShadow: "0px 8px 16px rgba(220, 220, 220, 0.5)",
        }}
      >
        <Toolbar sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "end",
              flexDirection: "row",
              gap: "8px",
              textDecoration: "none",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          >
            <img src={BxiLogo} alt="" width="45px" />
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                textAlign: "center",
                color: "#000",
                mb: "-4px",
                textTransform: "uppercase",
              }}
            >
              Barter Exchange of India
            </Typography>
          </Box>

          {isMatch ? (
            <>
              <DrawerComp />
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "right",

                  marginLeft: "auto",
                }}
              >
                <TabContext
                  textColor="#252525"
                  value={value}
                  onChange={(event, newValue) => setValue(newValue)}
                  indicatorColor="primary"
                >
                  {pages.map((page, index) => (
                    <Tab
                      key={index}
                      label={page.name}
                      value={page.id}
                      index={page.id}
                      sx={{
                        ...NavbarText,
                        color: "#000",
                        textTransform: "none",
                        opacity: 1,
                        m: "05px",
                        p: "0",
                        borderBottom:
                          currentLocation === page.text
                            ? "3px solid #375DBB"
                            : "3px solid transparent",
                        "&:hover": {
                          color: "#00000",
                          opacity: 1,
                          transition: " ease 1s",
                          borderBottom: "3px solid #375DBB",
                          fontWeight: 500,
                        },
                      }}
                      onClick={() => navigate(page.link)}
                      disableRipple
                    />
                  ))}
                </TabContext>
              </Box>

              <Box
                sx={{
                  ...NavbarText,
                  borderRadius: "17px",
                  border: "1px solid #252525",
                  padding: "12px",
                  width: "180px",
                  height: "18px",
                  textAlign: "center",
                  lineHeight: "10px",
                  gap: "8px",
                  mt: 0.3,
                  "&:hover": {
                    background: "#2261A2",
                    border: "1px solid #2261A2",
                    color: "#fff",
                  },
                }}
                onMouseOver={() => {
                  setImgArr(true);
                }}
                onMouseOut={() => {
                  setImgArr(false);
                }}
                onClick={() => {
                  handleToast();
                }}
              >
                Login / Sign Up
                <Box
                  component="img"
                  src={imgArr ? whitearr : Arrow}
                  alt="Arrow"
                  sx={{
                    height: "auto",
                    width: "30px",
                  }}
                />
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Navbar;

const NavbarText = {
  marginLeft: "16px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "18px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#252525",
  cursor: "pointer",
};
